import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import Container from 'components/Container';

import logo from 'assets/images/logo.png';

const Header = () => {
  React.useEffect(() => {
    const header = document.getElementById('EYHeader');
    const scrollCallBack = window.addEventListener('scroll', () => {
      if (window.pageYOffset > 678) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    });
    return () => {
      window.removeEventListener('scroll', scrollCallBack);
    };
  }, []);

  return (
    <header id='EYHeader'>
      <Container>
        <p>Enlightening the next generation</p>
        <ul>
          <li>
            <AnchorLink to='/#about' title='About' stripHash>
              About
            </AnchorLink>
          </li>
          <li>
            <AnchorLink to='/#speakers' title='Speakers' stripHash>
              Speakers
            </AnchorLink>
          </li>
          <li>
            <a
              href='mailto:info@vodw.be?subject=Contact me about the enlightenment sessions'
              className='button'
            >
              Get involved
            </a>
          </li>
          <li className='logo'>
            <a href='https://www.ey.com/' target='_blank' rel='noreferrer'>
              <img src={logo} alt='EY logo' />
            </a>
          </li>
        </ul>
      </Container>
    </header>
  );
};

export default Header;
